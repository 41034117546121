import { useEffect } from "react";
import { Box, Button, Paper } from "@mui/material";
import LoggedOutLayout from "layouts/LoggedOutLayout";
import { LOCAL_STORAGE } from "constants/global";
import Image from "next/image";
import FullLogo from "assets/full_logo.png";
import { shutdownIntercom } from "lib/intercom";
import API_URL from "../constants/api-urls";

export default function LoggedOutPage() {
  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE.defaultOrganizationId);
  });
  useEffect(() => {
    shutdownIntercom();
  }, []);

  return (
    <LoggedOutLayout>
      <Paper
        variant="outlined"
        square
        elevation={0}
        sx={{
          textAlign: "center",
          p: 2,
          pt: 3,
          pb: 4,
          mt: 6,
        }}
      >
        <Box sx={{ pb: 2 }}>
          <Image src={FullLogo} alt="DevZero Logo" width={518} height={194} />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          href={API_URL.authLogin}
        >
          Sign In
        </Button>
      </Paper>
    </LoggedOutLayout>
  );
}
