import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { UserContext } from "@auth0/nextjs-auth0";
// import { useTranslation } from "next-i18next";
import LoggedOutPage from "components/LoggedOutPage";
import FullPageLoading from "components/FullPageLoading";
import useUser from "fetchers/use-user";
import { useDZUser as useNextUser } from "utils/auth0";
import { useRouter } from "next/router";
import axios from "axios";
import { useEffect, useState } from "react";
import API_URL from "constants/api-urls";
import { Locale } from "../LocaleType";

function Home() {
  // const { t } = useTranslation("common");
  const router = useRouter();
  const [createdUser, setCreatedUser] = useState(false);
  const nextUserData = useNextUser();
  const { user, isLoading } = useUser(createdUser, null);
  const [invitedUser, setInvitedUser] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const NDU =
    process.env.NEXT_PUBLIC_VAULTED_AUTH === "true"
      ? {
          isLoading: (nextUserData as { status: string }).status,
          user: (nextUserData as unknown as { data: never }).data,
        }
      : {
          user: (nextUserData as UserContext).user,
          isLoading: (nextUserData as UserContext).isLoading,
        };

  const nextUserIsLoading = NDU.isLoading;

  // TODO consider moving this out to a custom hook
  useEffect(() => {
    async function createUser() {
      if (!NDU.user) return;
      try {
        await axios.post(API_URL.createUser, {});
        setCreatedUser(true);
      } catch (error) {
        // cannot create user because user is not in an org
        setInvitedUser(false);
      }
    }
    createUser();
  }, [NDU.user]);

  const haveFullUser = nextUserData && user;

  // if there is no user and the user isnt loading anymore, show logged out page
  // this prevents user from seeing the logged out page when the user is still loading
  if (!NDU.user && !nextUserIsLoading) {
    // this changes in box, assign to variable based on env var and normalize
    return <LoggedOutPage />;
  }
  if (haveFullUser) {
    router.push("/dashboard");
  }
  if (!invitedUser) {
    router.push("/not-found");
  }
  if (nextUserIsLoading || isLoading) {
    return <FullPageLoading />;
  }
  return null;
}

export const getStaticProps = async ({ locale }: Locale) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common", "footer"])),
  },
});

export default Home;
