export const VIEW_MODES = {
  EDIT: "EDIT",
  VIEW: "VIEW",
  COPY: "COPY",
};

export const drawerWidth = 278;

export const docsWidth = 360;

export const LOCAL_STORAGE = {
  defaultOrganizationId: "defaultOrganizationId",
};

export const OAUTH_WINDOW_NAME = "persistAuthToken";

export const MAIN_URL = "https://console.devzero.io";

export const SWR_REFETCH_INTERVAL_MS = 75000;

export const TEMPLATE_METADATA_REFETCH_INTERVAL_MS = 7500;

export const NEW_TEMPLATE_SUGGESTION_STRING = 
`# # This is a sample template which creates a python server
# ## This section below is for Debian packages you want to be pre-installed in your workspace by apt-get
# softwarepolicy:
# - packagename: net-tools ### add a line like this one for each package to be installed
# - packagename: zsh
# ## This section below adds a specified git repository to a predefined path
# repopolicy:
#  - path: /home/devzero/projects
#    repourl: https://github.com/hashicorp/vault # will clone vault
# ## This section below is for scripts to execute sequentially once the specified packages are installed
# scriptpolicy:
# - script: |
#     echo "fooo" > /home/devzero/projects/foo.txt
# - script: |
#     cd /home/devzero/
#     /usr/bin/python3 -m http.server 8000 &> /dev/null &
#   runas: devzero
# ## This section below allows you to define ports to be exposed and their associated protocols
# networkpolicy:
#   ports:
#   - port: 8000
#     protocol: http
#     name: fileserver`;

export const USER_POLICY_SUGGESTION_STRING = 
`# # This is a sample user policy
# ## This section below is for Debian packages you want to be pre-installed in all of your workspaces by apt-get
# softwarepolicy:
# - packagename: fzf ### add a line like this one for each package to be installed
# - packagename: net-tools
#
# ## This section below is for scripts to execute sequentially once the specified packages are installed
# ## Each script block is executed in a separate '#!/usr/bin/env bash' shell
# scriptpolicy:
# - script: |
#     echo "fooo" > /home/devzero/foo.txt
# - script: |
#     echo 'export EDITOR=vi' >> ~/.bashrc
#     echo 'alias code=$(find ~/.vscode-server/ -name code)' >> ~/.bashrc
# - script: |
#     /home/devzero/.vscode-server/bin/code-server --install-extension GitHub.copilot`;

export const SUPPORT_EMAIL = "support@devzero.io";

export const TRYDZ_ORG_ID = "og-e2ac5492b55a251b8775cc69aecf0fe3";
export const DZ_ORG_ID = "og-00000000dededededede000000000000";

export const INTERCOM_CONVERSION_TAG_ID = "7952633";
export const INTERCOM_LEAD_TAG_ID = "7952620";

export const MARKETING_WEBSITE_URL = "https://www.devzero.io";
