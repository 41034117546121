import { track } from "@amplitude/analytics-browser";

declare global {
  interface Window {
    dz: any;
    dataLayer: any;
    intercomSettings: any;
  }
}

export function trackGtm(props: any) {
  try {
    window.dataLayer.push(props);
  } catch (e) {
    console.log("analytics error:", e);
  }
}

export function trackEvent(name: string, props: any | undefined = undefined) {
  track(name, props);
}
