import { Container, Typography, Link } from "@mui/material";
import ContactSupport from "./ContactSupport";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://devzero.io">
        DevZero
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}
function Footer() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, marginTop: "auto" }}>
      <ContactSupport sx={{ pt: 4 }} />
      <Copyright />
    </Container>
  );
}

export default Footer;
