import { initAuth0, withPageAuthRequired, useUser } from "@auth0/nextjs-auth0";
import { useSession } from "next-auth/react";

// initializes custom auth0 for SPAM flow

export default initAuth0({
  secret: process.env.TRY_AUTH_SECRET,
  issuerBaseURL: process.env.AUTH0_ISSUER_BASE_URL,
  clientID: process.env.TRY_AUTH_CLIENT_ID,
  clientSecret: process.env.TRY_AUTH_CLIENT_SECRET,
});


export const withDZPageAuthRequired = (children: never) => {
  if (process.env.NEXT_PUBLIC_VAULTED_AUTH === "true") {
    return children
  }
  return withPageAuthRequired(children as never)
};

export const useDZUser = process.env.NEXT_PUBLIC_VAULTED_AUTH === "true" ? useSession :  useUser;