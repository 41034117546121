import API_URL from "constants/api-urls";
import useSWR, { KeyedMutator } from "swr";
import { ModelsUser } from "@devzero-inc/api-client";

type UseUser = {
  user: ModelsUser;
  isLoading: boolean;
  isError: boolean;
  mutate: KeyedMutator<ModelsUser>;
};
function useUser(shouldFetch = true, options: any = {}): UseUser {
  const { data, error, mutate } = useSWR(
    shouldFetch ? API_URL.getUserInfo : null,
    options
  );

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export default useUser;
