import { ReactNode } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Head from "next/head";
import Footer from "components/Footer";
import { Box, Container } from "@mui/material";
import muiTheme from "constants/mui";
import { SxProps, Breakpoint } from "@mui/system";

interface LayoutProps {
  children: ReactNode;
  containerMaxWidth?: false | Breakpoint | undefined;
  containerSx?: SxProps;
}
const defaultProps = {
  containerMaxWidth: "sm",
  containerSx: { my: 4 },
};
const otherTheme = createTheme(muiTheme("light"));

export default function LoggedOutLayout(props: LayoutProps) {
  const { children, containerMaxWidth, containerSx } = props;
  return (
    <>
      <Head>
        <title>DevZero</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="description" content="DevZero management webapp" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ThemeProvider theme={otherTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth={containerMaxWidth} sx={containerSx}>
              {children}
            </Container>
            <Footer />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

LoggedOutLayout.defaultProps = defaultProps;
