import { PaletteMode } from "@mui/material";

const purple = {
  120: "#7B5CB5",
  100: "#A67DF4",
  40: "#DBCBFB",
};

const blue = {
  120: "#5E6CAD",
  100: "#7F93ED",
  40: "#CCD4F8",
};

const teal = {
  120: "#2089A1",
  100: "#2CC0E0",
  40: "#ABE6F3",
};

const red = {
  120: "#A84F3B",
  100: "#E86C51",
  40: "#F57356",
  10: "#FBBAAC",
};

const pink = {
  120: "#A444A6",
  100: "#E35DE5",
  40: "#EE9EF0",
};

const green = {
  120: "#718742",
  100: "#A7C761",
  40: "#B1D468",
};

const gray = {
  100: "#F8F5FF",
  80: "#F9F7FF",
  60: "#FBF9FF",
  40: "#FCFBFF",
  20: "#FEFDFF",
};

declare module "@mui/material/styles" {
  interface Palette {
    defaultBackground: Palette["primary"];
    hibernationBackground: Palette["primary"];
    error: Palette["primary"];
    drawer: Palette["primary"];
    instanceBackground: Palette["primary"];
    editorBackground: Palette["primary"];
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    code1: React.CSSProperties;
    code2: React.CSSProperties;
    code3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    code1?: React.CSSProperties;
    code2?: React.CSSProperties;
    code3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    code1: true;
    code2: true;
    code3: true;
  }
}

const muiTheme = (mode: PaletteMode) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1008,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: purple[100],
            light: purple[40],
            dark: purple[120],
            contrastText: "#FFFFFF",
            containedHoverBackground: purple[120],
            outlinedHoverBackground: "#a67df433",
            outlinedRestingBackground: purple[100],
          },
          secondary: {
            main: blue[100],
            light: blue[40],
            dark: blue[120],
            contrastText: "#FFFFFF",
            containedHoverBackground: blue[120],
            outlinedHoverBackground: blue[40],
            outlinedRestingBackground: blue[100],
          },
          text: {
            primary: "#333333",
            secondary: "#707070",
            disabled: "#33333366",
          },
          defaultBackground: {
            main: "#FCFBFF",
            light: "#FCFBFF",
          },
          hibernationBackground: {
            main: "#FFFFFFB3",
          },
          action: {
            active: "#267DBF4D",
            hover: "#267DBF14",
            selected: "#267DBF33",
            disabled: "#33333366",
            disabledBackground: "#3333331F",
            focus: "#267DBF1F",
          },
          warning: {
            main: "#e2c25a",
            // main: pink[100],
            // dark: pink[120],
            // light: pink[40],
          },
          error: {
            main: red[100],
            light: red[40],
            dark: red[120],
            contrastText: "#FFFFFF",
            containedHoverBackground: red[120],
            outlinedHoverBackground: red[10],
            outlinedRestingBackground: red[100],
          },
          info: {
            main: teal[100],
            dark: teal[120],
            light: teal[40],
          },
          success: {
            main: green[100],
            dark: green[120],
            light: green[40],
          },
          drawer: {
            main: "#FFFFFF",
            light: "#b6b6b6",
          },
          instanceBackground: {
            main: "transparent",
            light: "#00000014",
          },
          divider: "#3333331A",
          editorBackground: {
            main: "#ffffff",
          },
        }
      : {
          primary: {
            main: purple[100],
            light: purple[40],
            dark: purple[120],
            contrastText: "#FFFFFF",
            containedHoverBackground: purple[120],
            outlinedHoverBackground: "#a67df433",
            outlinedRestingBackground: purple[100],
          },
          secondary: {
            main: blue[100],
            light: blue[40],
            dark: blue[120],
            contrastText: "#FFFFFF",
            containedHoverBackground: blue[120],
            outlinedHoverBackground: blue[40],
            outlinedRestingBackground: blue[100],
          },
          text: {
            primary: "#FFFFFF",
            secondary: "#FFFFFF",
            disabled: gray[40],
          },
          defaultBackground: {
            main: "#22222a",
            light: "#191828",
          },
          hibernationBackground: {
            main: "#2f2e3eb3",
          },
          action: {
            active: "#267DBF4D",
            hover: "#267DBF45",
            selected: "#267DBF33",
            disabled: "#d7d5d566",
            disabledBackground: "#3333331F",
            focus: "#267DBF1F",
          },
          warning: {
            main: "#e2c25a",
            // main: pink[100],
            // dark: pink[120],
            // light: pink[40],
          },
          error: {
            main: red[100],
            light: red[40],
            dark: red[120],
            contrastText: "#FFFFFF",
            containedHoverBackground: red[120],
            outlinedHoverBackground: red[10],
            outlinedRestingBackground: red[100],
          },
          info: {
            main: teal[100],
            dark: teal[120],
            light: teal[40],
          },
          success: {
            main: green[100],
            dark: green[120],
            light: green[40],
          },
          drawer: {
            main: "#2f2e3e",
            light: "#ffffff33",
          },
          instanceBackground: {
            main: "transparent",
            light: "#f5f5f51a",
          },
          divider: "#ffffff1f",
          background: {
            paper: "#242334",
          },
          editorBackground: {
            main: "#1e1e1e",
          },
        }),
  },
  typography: {
    fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
      ","
    ),
    h1: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: "120%",
      letterSpacing: "-0.5px",
      fontFamily: [
        "Gilroy",
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: "120%",
      letterSpacing: "-0.5px",
      fontFamily: [
        "Gilroy",
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "120%",
      letterSpacing: "0.25px",
      fontFamily: [
        "Gilroy",
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "120%",
      letterSpacing: "0.25px",
      fontFamily: [
        "Gilroy",
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0.5px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0.5px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "-0.25px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "-0.25px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.15px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "0.25px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.15px",
      fontFamily: ["Open Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      ...(mode === "light" && { color: "#333333B2" }),
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "200%",
      letterSpacing: "0.5px",
      fontFamily: [
        "Gilroy",
        "Open Sans",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      textTransform: "uppercase" as const,
    },
    code1: {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: "monospace",
    },
    code2: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "monospace",
    },
    code3: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "monospace",
      lineHeight: "120%",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: 13,
          fontWeight: 900,
          lineHeight: "22px",
          letterSpacing: "0.5px",
          fontFamily: [
            "Gilroy",
            "Open Sans",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
          ].join(","),
        },
        sizeMedium: {
          fontSize: 14,
          fontWeight: 900,
          lineHeight: "24px",
          letterSpacing: "0.75px",
          fontFamily: [
            "Gilroy",
            "Open Sans",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
          ].join(","),
        },
        sizeLarge: {
          fontSize: 16,
          fontWeight: 900,
          lineHeight: "26px",
          letterSpacing: "0.75px",
          padding: "15px 22px",
          fontFamily: [
            "Gilroy",
            "Open Sans",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
          ].join(","),
        },
        root: {
          "&.Mui-disabled": {
            color: mode === "light" ? "#33333366" : "#e5e5e566",
            backgroundColor: mode === "light" ? "##3333331F" : "#e5e5e51f",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#AAD3F240",
            "&:hover": {
              backgroundColor: "#AAD3F240",
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: blue[100],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Gilroy",
            "Open Sans",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
          ].join(","),
        },
        colorInfo: {
          backgroundColor: mode === "light" ? "#e0e0e0" : "#636363a8",
          color: mode === "light" ? "#333333" : "#ffffff",
        },
        icon: {
          "&.MuiChip-icon": {
            color: mode === "light" ? "#33333366" : "#cccccc66",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "&.MuiAlert-filledSuccess": {
            backgroundColor: green[100],
          },
          "&.MuiAlert-filledInfo": {
            backgroundColor: teal[100],
          },
          "&.MuiAlert-filledWarning": {
            backgroundColor: pink[100],
          },
          "&.MuiAlert-filledError": {
            backgroundColor: red[100],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 20,
          height: 10,
          padding: 0,
          display: "flex",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 6,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(10px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
              transform: "translateX(10px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: blue[100],
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            width: 6,
            height: 6,
            borderRadius: 3,
          },
          "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: "rgba(0,0,0,.25)",
            boxSizing: "border-box",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "48px",
          fontFamily: [
            "Gilroy",
            "Open Sans",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
          ].join(","),
          fontWeight: 600,
          fontSize: "13px",
          marginRight: "48px",
          padding: "8px",
          "&:last-of-type": {
            marginRight: "0px",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...(mode === "light" && { backgroundColor: "#ffffff" }),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          boxShadow: "0px 2px 4px 0px #33333324, 0px 1px 8px 0px #3333331F ",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: mode === "light" ? "#3333331A" : "#ffffff1f",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: "1px dashed",
            borderColor:
              mode === "light"
                ? "#3333331A !important"
                : "#ffffff1f !important",
          },
        },
      },
    },
    // need to figure out how to widen the border behing the label
    // MuiInputLabel: {
    //   styleOverrides: {
    //     shrink: {
    //       textTransform: "uppercase",
    //       fontFamily: [
    //         "Gilroy",
    //         "Open Sans",
    //         '"Helvetica Neue"',
    //         "Arial",
    //         "sans-serif",
    //       ].join(","),
    //       letterSpacing: "0.5px",
    //     },
    //   },
    // },
  },
});
export default muiTheme;
