import axios from "axios";
import forge from "node-forge";
import API_URL from "constants/api-urls";
import {
  INTERCOM_CONVERSION_TAG_ID,
  INTERCOM_LEAD_TAG_ID,
  TRYDZ_ORG_ID,
} from "constants/global";
import { trackGtm } from "./analytics";

export const initializeIntercom = (
  userId: string | undefined,
  email: string | null | undefined,
  firstName: string | undefined,
  lastName: string | undefined,
  orgId: string | undefined,
  orgName: string | undefined,
  userRole: string | undefined
) => {
  if (email) {
    // creating a hash for the user for intercom to do identity verification
    const hmac = forge.hmac.create();
    hmac.start("sha256", process.env.NEXT_PUBLIC_INTERCOM_SECRET as string);
    hmac.update(email);
    const hashText = hmac.digest().toHex();
    const userAgentString = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    const isMobileDevice = regexp.test(userAgentString);
    trackGtm({
      event: "intercom_init",
      // user_id: userId,
      org_id: orgId,
      org_name: orgName,
      user_hash: hashText,
      user_email: email,
      user_type: orgId === TRYDZ_ORG_ID ? "Quickstart" : "Commercial",
      first_name: firstName,
      last_name: lastName,
      user_role: userRole,
      device_last_seen: isMobileDevice ? "mobile" : "desktop",
    });
  }
};

export const shutdownIntercom = () => {
  trackGtm({
    event: "intercom_shutdown",
  });
};

async function updateExistingLead(id: string, conversion: boolean) {
  const postBody = {
    id: conversion ? INTERCOM_CONVERSION_TAG_ID : INTERCOM_LEAD_TAG_ID,
  };
  await axios.post(`${API_URL.intercomAddTag}${id}`, postBody);
}

export default async function findAndSaveLead(
  email: string | null | undefined,
  conversionTag: boolean
) {
  try {
    // check if already created
    const searchBody = {
      query: {
        operator: "AND",
        value: [
          {
            field: "email",
            operator: "=",
            value: email,
          },
          {
            field: "role",
            operator: "=",
            value: "user",
          },
        ],
      },
    };
    await axios.post(API_URL.intercomSearchContacts, searchBody).then((res) => {
      if (res.data.data.length > 0 && conversionTag) {
        // adding Quickstart conversion tag to existing user
        updateExistingLead(res.data.data[0].id, true);
      }
    });
  } catch (e) {
    console.log(e);
  }
}

async function initiateConversation(id: string, message: string) {
  const postBody = {
    from: {
      type: "user",
      id,
    },
    body: message,
  };
  await axios.post(`${API_URL.intercomConversations}`, postBody);
}

export async function findLeadCreateConversation(
  email: string | null | undefined,
  message: string
) {
  try {
    const searchBody = {
      query: {
        operator: "AND",
        value: [
          {
            field: "email",
            operator: "=",
            value: email,
          },
          {
            field: "role",
            operator: "=",
            value: "user",
          },
        ],
      },
    };
    await axios.post(API_URL.intercomSearchContacts, searchBody).then((res) => {
      if (res.data.data.length > 0) {
        initiateConversation(res.data.data[0].id, message);
      }
    });
  } catch (e) {
    console.log(e);
  }
}
