const API_URL = {
  acquireMachine: "/api/acquire-machine/",
  addSecretManagerArn: "/api/add-secret-manager-arns/",
  addSSHPublicKey: "/api/add-pubkey/",
  authLogin:
    process.env.NEXT_PUBLIC_VAULTED_AUTH === "true"
      ? "/api/vaulted-auth/signin"
      : "/api/auth/login",
  cancelValidateTemplate: "/api/cancel-validate-template/",
  copyGithubKeys: "/api/copy-github-keys/",
  createEventBridge: "/api/create-event-bridge/",
  createGithubApp: "/api/create-github-app/",
  createGitlabDeployToken: "/api/create-gitlab-deploy-token/",
  createInquiry: "/api/create-inquiry-user/",
  createKubeMachine: "/api/create-kube-machine/",
  createOrganization: "/api/create-organization/",
  createSnapshot: "/api/create-snapshot",
  createUser: "/api/create-user-info/",
  deleteCodeProvider: "/api/delete-code-provider/",
  deleteGithubApp: "/api/delete-github-app/",
  deleteKubeMachine: "/api/delete-kube-machine-by-id/",
  deleteKubeTemplate: "/api/delete-kube-template/",
  deleteMachine: "/api/delete-machine/",
  deleteOrganizationInvitation: "/api/delete-organization-invitation/",
  deleteOrganizationMember: "/api/delete-organization-member/",
  deletePubkey: "/api/delete-pubkey/",
  deleteSecretManagerArn: "/api/delete-secret-manager-arn/",
  deleteShareLink: "/api/delete-share-link/",
  deleteTemplate: "/api/delete-template/",
  dismissStatusPageId: "/api/dismiss-status-page-id/",
  getAllocatedIPs: "/api/get-allocated-ips/",
  getAvailableTemplates: "/api/get-available-templates/",
  getCloudProviderMetadata: "/api/get-cloud-provider-metadata/",
  getCodeProviders: "/api/get-code-providers/",
  getDotfiles: "/api/user-dotfiles/",
  getGithubApps: "/api/get-github-apps/",
  getGitlabConfiguration: "/api/get-gitlab-configuration/",
  getGitlabDeployTokens: "/api/get-gitlab-deploy-tokens/",
  getKubeClusters: "/api/get-kube-clusters-for-organization/",
  getKubeImages: "/api/get-kube-image-org-id/",
  getKubeMachines: "/api/get-kube-machines-by-organization/",
  getKubeTemplates: "/api/get-kube-templates-by-organization/",
  getMyMachines: "/api/get-my-machines/",
  getMyOrganizations: "/api/get-my-organizations/",
  getOrganization: "/api/get-organization/",
  getOrganizationCosts: "/api/get-organization-costs/",
  getOrganizationMachineMetadata: "/api/get-organization-machines-metadata/",
  getOrganizationMembers: "/api/get-organization-members/",
  getOrganizations: "/api/get-organizations-list/",
  getOrgRegions: "/api/get-organizations-regions/",
  getRegions: "/api/get-regions-list/",
  getPhysicalMachineSpecs: "/api/get-physical-machine-specs/",
  getPubkeys: "/api/get-pubkeys/",
  getShareLink: "/api/get-share-link/",
  getStatusPage: "/api/status-page/",
  getTemplateEditHistory: "/api/get-template-edit-history/",
  getTemplateMetadata: "/api/get-template-metadata/",
  getTemplateSecrets: "/api/get-template-secrets/",
  getUserInfo: "/api/get-user-info/",
  getUserSecrets: "/api/get-user-secrets/",
  intercomAddTag: "/api/intercom-add-tag/",
  intercomConversations: "/api/intercom-conversations/",
  intercomCreateContact: "/api/intercom-create-contact/",
  intercomSearchContacts: "/api/intercom-search-contacts/",
  linkCodeProvider: "/api/link-code-provider/",
  linkGithub: "/api/link-github/",
  linkGithubOrganization: "/api/link-github-organization/",
  postPersonalPolicy: "/api/post-personal-policy",
  postUserInfo: "/api/post-user-info/",
  setAllocatedIPs: "/api/set-allocated-ips/",
  sendOrganizationInvitation: "/api/send-organization-invitation/",
  updateCloudProviderMetadata: "/api/update-cloud-provider-metadata/",
  updateCodeProvider: "/api/update-code-provider/",
  updateGitlabCredentials: "/api/update-gitlab-credentials/",
  updateMachine: "/api/update-machine/",
  updateOrganization: "/api/update-organization/",
  updateOrganizationMember: "/api/update-organization-member/",
  updateOrganizationRegions: "/api/update-organization-regions/",
  updateUserLifecycleEvents: "/api/update-user-lifecycle-events/",
  updateFavoriteTemplate: "/api/update-favorite-template/",
  updateGithubProvider: "/api/update-github-provider",
  updateTemplateColor: "/api/update-template-color/",
  upgradeMachine: "/api/upgrade-machine/",
  upsertShareLink: "/api/upsert-share-link/",
  upsertTemplate: "/api/upsert-template/",
  upsertTemplateAlpha: "/api/upsert-template-alpha",
  upsertTemplateSecrets: "/api/upsert-template-secrets/",
  upsertUserSecrets: "/api/upsert-user-secrets/",
  validateTemplate: "/api/validate-template/",
  getRoleAccess: "/api/get-role-access/",
  getServiceQuotas: "/api/get-service-quotas/",
  requestServiceQuotas: "/api/request-service-quotas/",
};

export default API_URL;
