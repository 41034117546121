import { Link, Typography } from "@mui/material";
import { SUPPORT_EMAIL } from "constants/global";

export default function ContactSupport(props: any) {
  return (
    <Typography
      variant="body2"
      align="center"
      color="text.secondary"
      {...props}
    >
      Need Help? <Link href={`mailto:${SUPPORT_EMAIL}`}>Contact Support</Link>
    </Typography>
  );
}
